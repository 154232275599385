import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

import "./CustomersSection.css";
import inducortes from "Assets/images/inducortes-gray.webp";
import fermar from "Assets/images/fermar-gray.webp";
import renagew from "Assets/images/renagew-gray.webp";
import expressfarm from "Assets/images/expressfarm-gray.webp";
import refrisander from "Assets/images/refrisander-gray.webp";
import zirus from "Assets/images/zirus-gray.webp";
import cypres from "Assets/images/cypress-gray.webp";
import pasteurlab from "Assets/images/pasteurlab-gray.webp";
import rotular from "Assets/images/rotular-gray.webp";
import hotelterra from "Assets/images/logo-terra.png";
import libardino from "Assets/images/logo-libardino.webp";
import gaaf from "Assets/images/logo-gaaf.webp";
import bolivar from "Assets/images/logo-bolivar.webp";
import duohotel from "Assets/images/logo-duohotel.webp";
import bialab from "Assets/images/logo-bialab.webp";


const CarouselCustomers = () => {

const logos = [
["zirus", zirus, [180, 80], "https://www.zirus.pizza/"],
["pasteurlab", pasteurlab, [230, 100], "https://pasteurlab.com/"],
["inducortes", inducortes, [180, 90], "http://www.inducortes.com/"],
["rotular", rotular, [180, 80], "https://rotular.com.co/"],
["fermar", fermar, [180, 110], "http://www.fermarsas.com/"],
["renagew", renagew, [180, 80], "https://renagew.com/"],
["expressfarm", expressfarm, [240, 100], "https://www.expressfarmsas.com/"],
["refrisander", refrisander, [180, 80], "https://www.refrisander.com/"],
["cypres", cypres, [200, 100], "https://casascypres.com/"],
["hotel terra", hotelterra, [220, 100], "https://www.utredintegradafoscal-cub.com/"],
["libardino", libardino, [220, 100], "https://www.utredintegradafoscal-cub.com/"],
["gaaf", gaaf, [200, 100], "https://www.utredintegradafoscal-cub.com/"],
["bolivar", bolivar, [220, 100], "https://www.utredintegradafoscal-cub.com/"],
["bialab", bialab, [220, 100], "https://www.utredintegradafoscal-cub.com/"],
["duo hotel", duohotel, [220, 100], "https://www.utredintegradafoscal-cub.com/"],
];


const renderLogos = () => {
let itemsPerSlide;

if (window.innerWidth >= 1000) /* {
	itemsPerSlide = 6;
} else if (window.innerWidth >= 100) */ {
	itemsPerSlide = 5;
} else if (window.innerWidth >= 500) {
	itemsPerSlide = 5;
} else {
	itemsPerSlide = 2;
}

const totalSlides = Math.ceil(logos.length / itemsPerSlide);
const slides = [];

for (let i = 0; i < totalSlides; i++) {
	const start = i * itemsPerSlide;
	const end = start + itemsPerSlide;
	const slideLogos = logos.slice(start, end);

	slides.push(
	<div key={i} className="flex px-2 md:px-10 py-5 md:py-0 justify-center items-center gap-5 md:gap-8">
		{slideLogos.map((logo, j) => (
		<a href={logo[3]} target="_blank" rel='noreferrer' key={j}>
			<img
			key={j}
			src={logo[1]}
			alt="imagenes"
			className=" h-32 md:h-52 object-scale-down grayscale "
		/>
		</a>
		))}
	</div>
	);
}

return slides;
};
return (
<div className="flex mx-auto md:w-11/12 w-screen my-0">
	<div className="carousel-wrapper">
		<Carousel
		interval={5000}
		infiniteLoop
		showThumbs={false}
		showStatus={false}
		showIndicators={false}
		transitionTime={2000}
		emulateTouch
		stopOnHover={true}
		swipeable
		renderThumbs={() => null}
		renderArrowPrev={(onClickHandler, hasPrev) =>
			hasPrev && (
			<button
				type="button"
				onClick={onClickHandler}
				className="arrow  left-0 ">
			<ChevronLeftIcon className='text-white hover:bg-yellow-300 hover:text-black  rounded-full hover:cursor-pointer hover:scale-125 transform duration-200 w-8 md:w-14 md:h-14 bg-bluePresik p-2  '/>
			</button>
			)}

		renderArrowNext={(onClickHandler, hasNext) =>
			hasNext && (
			<button
				type="button"
				onClick={onClickHandler}
				className=" arrow right-0 py-12 px-2 absolute bg-white">
				<ChevronRightIcon className='text-white hover:bg-yellow-300 hover:text-black  rounded-full hover:cursor-pointer hover:scale-125 transform duration-200 w-8 md:w-14 md:h-14 bg-bluePresik p-2'/>
			</button>
			)}>
	{renderLogos()}
	</Carousel>
</div>
</div>
);
};

export default CarouselCustomers;