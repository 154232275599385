import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

const DropdownMenu = ({ menu, childs, t, styleText = "text-gray-900" }) => {
  const numChilds = childs ? childs.length : 0;

  return (
    <div>

      <Menu>
        {childs && childs.length != 0 ? (
          <Menu.Button
            className={`font-roboto group text-lg w-full flex justify-between items-center ${styleText} font-normal   px-2 py-3 lg:py-0`}
          >
            {/* <div className='h-5 w-5 z-20 rounded-md bg-yellow-400 transform rotate-45 opacity-50 group-hover:rotate-90 group-hover:bg-blue-600 transition-all duration-500'></div> */}

            <span className="z-10">{t(`menu.${menu.name}`)}</span>

            <ChevronRightIcon className="h-4 w-4 lg:hidden transform rotate-90 md:rotate-0 " />
          </Menu.Button>
        ):(
            <a
              href={`/${menu.name}/`}
              className={`font-roboto group text-lg w-full flex justify-between items-center ${styleText} font-normal   px-2 py-3 lg:py-0`}
            >
              {/* <div className='h-5 w-5 ml-1 rounded-md bg-yellow-400 transform rotate-45 opacity-50 '></div> */}
              <span className="z-10">{t(`menu.${menu.name}`)}</span>
            </a>
        )}
        

        <Transition
          as={Fragment}
          enter="transition-opacity duration-75"
          enterFrom="-translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Menu.Items
            className={`static bg-white-presik md:absolute grid p-2 md:p-2 gap-4 origin-top w-full left-40 md:left-0 md:top-12  rounded-md   -translate-x-full opacity-0 transition-opacity duration-500 ease-in-out z-50 ${
              numChilds === 1
                ? "w-48  md:mt-0 md:ml-0 ml-2 grid-cols-1"
                : "w-52 ml-2  md:mt-0 md:ml-0 md:w-96 grid-cols-1 md:grid-cols-2 "
            }`}
            style={{
              minWidth: "450px",
              backgroundColor:
                "background: #ffffffcc;	backdrop-filter: blur(8px);",
            }}
          >
            {/* <div className='hidden md:block h-3 bg-white w-3 border-t border-l border-gray-300 absolute -top-2 left-7 transform rotate-45 '></div> */}

            {/* <ChevronUpIcon className='h-6 -z-0 w-40 absolute iconMenu text-gray-300' /> */}

            {childs &&
              childs.length != 0 &&
              childs.map((c) => (
                <div key={c}>
                  <Menu.Item>
                    <div className="">
                      <a
                        href={`/${menu.name}/${c}`}
                        className="bg-white md:bg-transparent  rounded-md flex  items-center hover:bg-white text-gray-900 hover:text-gray-800  py-2 lg:py-3 px-2 pl-4 text-xl md:text-lg font-normal"
                      >
                        {/* <div className='h-5 w-5 ml-1 rounded-md bg-yellow-400 transform rotate-45 opacity-50 '></div> */}
                        <span className="z-10">{t(`menu.${c}`)} </span>
                      </a>
                    </div>
                  </Menu.Item>
                </div>
              ))}
              <div >
                  <Menu.Item>
                    <div className="">
                      <a
                        href="blog.presik.com"
                        className="bg-white md:bg-transparent  rounded-md flex  items-center hover:bg-white text-gray-900 hover:text-gray-800  py-2 lg:py-3 px-2 pl-4 text-xl md:text-lg font-normal"
                      >
                        {/* <div className='h-5 w-5 ml-1 rounded-md bg-yellow-400 transform rotate-45 opacity-50 '></div> */}
                        <span className="z-10">Blog </span>
                      </a>
                    </div>
                  </Menu.Item>
                  </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default DropdownMenu;
