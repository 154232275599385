
const Loading = () => {
  return (
    <div className="bg-yellow-300 w-screen h-screen flex justify-center align-middle items-center flex-col relative uppercase">
        <span className="text-3xl">presik.com</span>
        <span className="h-1.5 w-6 bg-black animate-ping duration-300 relative -left-16 mt-3"></span>
    </div>
  )
}

export default Loading;
