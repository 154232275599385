import React from "react";
import { useTranslation } from "react-i18next";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";

import "./Home.css";

function ModuloEspecializados({ title, text, image, buttonText, buttonLink, inverted }) {
// eslint-disable-next-line no-unused-vars
const { t } = useTranslation();

return (
	<>
	{ inverted ? 
	
	<section className=" flex flex-col-reverse overflow-hidden  lg:flex-row justify-between lg:max-h-fit bg-gray-50" data-aos="fade-in">
	<div className="lg:w-6/12 relative overflow-hidden flex justify-end items-center w-full"><img src={image} alt="agroimg" className="hidden lg:flex img-section relative overflow-hidden duration-200  justify-end items-center w-full object-cover transform hover:scale-110 "/></div>	
	

	<div className=" lg:w-6/12 w-full relative flex flex-col justify-center pl-5 pr-5 lg:pl-28 lg:pr-40 pt-14 lg:space-y-9 space-y-5 pb-14 lg:pb-0">

	<h2 className="font-lato font-semibold lg:text-5xl text-3xl lg:w-full relative text-bg-bluePresik leading-none">
		{title}
	</h2>
	<p className="font-roboto lg:text-2xl text-lg text-bg-bluePresik font-light pb-2" style={{color:"#164e63"}}>{/* {t("home.electronic_billing")} */} {text}</p>

	<a
	className="font-roboto w-max uppercase flex gap-4 items-center text-2 lg:text-xl mt-4 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black"
	href={buttonLink}
	>{buttonText} <ArrowLongRightIcon className="h-8 text-black hover:scale-125" />
	</a>
	</div>

	<img src={image} alt="agro" className="lg:hidden flex img-section lg:w-6/12  relative overflow-hidden duration-200  justify-end items-center w-full object-cover transform hover:scale-110 "/>
</section>
	
	
	: 
	
	<section className="group flex flex-col-reverse lg:flex-row justify-between lg:max-h-fit overflow-hidden" data-aos="fade-in"  >
	<div className="lg:w-6/12 w-full relative flex flex-col justify-center pl-5 pr-5 lg:pl-28 lg:pr-40 pt-10 lg:space-y-9 space-y-5 pb-14 lg:pb-0">	

	<h2 className="font-lato font-semibold lg:text-5xl text-3xl lg:w-full relative text-bg-bluePresik leading-none">
		{title}
		</h2>
		<p className="font-roboto lg:text-2xl text-lg text-bg-bluePresik font-light lg:ight pb-2 " style={{color:"#164e63"}}>
			{text}
		</p>
 
		<a
		className="font-roboto w-max uppercase flex gap-4 items-center text-2 lg:text-xl mt-4 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black"
		href={buttonLink}
	>{buttonText} <ArrowLongRightIcon className="h-8 text-black hover:scale-125" />
	</a>
	</div> 
   <div className="lg:w-6/12 relative overflow-hidden flex justify-end items-center w-full">
	<img src={image} alt="hotel" className="img-section  object-cover duration-200 transform hover:scale-110 w-full"/>
	</div>
</section>
	}
	</>
);
}

export default ModuloEspecializados;