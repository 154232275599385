import React from "react";
import { useTranslation } from "react-i18next";

import PageTitleBig from "Components/PageTitleBig";
import PageBannerDesc from "Components/PageBannerDesc";
import "./PageBanner.css";

function PageBanner(props) {
  const { t } = useTranslation();

  return (
    <div className=" mt-20 container mx-auto" >
      <PageTitleBig title={props.title} align="left" size="maxi" />
      <PageBannerDesc text={props.description} />

    </div>
  );
}

export default PageBanner;
