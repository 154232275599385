import React from "react";
import { useTranslation } from "react-i18next";

/* import HeaderHome from "Home/HeaderHome"; */
import "./CustomersSection.css";
import CarouselCustomers from "./Carousel";


export default function Customers(props) {
  const { t } = useTranslation();

return (
  <section className="mt-10 md:mt-20 container flex justify-center flex-col mx-auto overflow-hidden py-0 pb-0 z-10" data-aos="fade-in" >
        <h2 className="font-lato text-bg-bluePresik md:text-6xl text-4xl uppercase font-semibold  text-center" >
          Nuestros <span className="">Clientes</span>
        </h2>

        <div className="mt-8 md:mt-10 px-5">
          <p className="font-roboto text-center text-lg md:text-2xl text-gray-700 font-light pb-2" style={{color:"#164e63"}}>{t("home.our_clients_desc")} </p>
        </div>

      <div className="flex justify-center md:my-4">
        <CarouselCustomers/>
    </div>

  </section>
);}
