export default [
  {
    name: "solutions",
    childs: [
      "tryton",
      "dashboard",
      "electronic_billing",
      "electronic_payroll",
      "integrations",
    ],
  },
  {
    name: "about_us",
    childs: ["team", "faq", "contact", "trabaje-con-nosotros"],
  },
  {
    name: "help",
    childs: ["documentation", "resources", "support"],
  },
];
