import React from "react";
import { useTranslation } from "react-i18next";

import "./PageTitleBig.css";

function PageBigTitle(props) {
  const { t } = useTranslation();

  let colorStyle = "white";
  if (props.color) {
    colorStyle = props.color;
  }

  return (
    <div >
      <h1 className=" text-white text-6xl uppercase font-semibold text-center">
        {t(props.title)}
      </h1>
    </div>
  );
}

export default PageBigTitle;
