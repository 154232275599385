import logoPresik from "Assets/images/logo-presik.png"
const jobs = [
    {
      id:1,
      slug:"vacante-chief-sales-officer",
      title: "VACANTE CHIEF SALES OFFICER",
      logo:logoPresik,
      salary: "2.300.000 + Prestaciones de ley",
      typeContract: "Tiempo completo",
      type: "Presencial",
      place: "Bucaramanga",
      contact:{
        phone: "+57 301 2457967",
        email: "gerente@presik.com",
        address: "gerente@presik.com",
      },
      shortDesc: `Una de las próximas empresas Tech mas relevantes en el país, esta buscando una
  persona que lidere el área de ventas, hay una vacante, el cargo es CHIEF SALES OFFICER,
  pero las habilidades que necesitas para llevar esta empresa al siguiente nivel, son
  básicamente: `,
      longDesc: `
        <p>Una de las próximas empresas Tech más relevantes en el país, está buscando una persona que lidere el área de ventas. El cargo es CHIEF SALES OFFICER y las habilidades necesarias son:</p>
        <ul class="my-4 list-disc list-inside">
          <li>Buenos conocimientos contables, válido desde Técnico Contable SENA hasta Contador(a) recién egresado(a).</li>
          <li>Pasión por la tecnología y las ventas.</li>
          <li>Disponibilidad para viajar a diferentes ciudades del país, aproximadamente un viaje por mes.</li>
          <li>Buen dominio de Excel.</li>
        </ul>
        <p class="mb-4">Ofrecemos un ambiente de trabajo increíble en una startup innovadora, con un trato humano y cálido. Es fácil pedir permiso para una diligencia, ver un partido de la Champions, o hacer una pausa para compartir un pan con café. Un día a la semana puedes trabajar desde casa y tendrás la oportunidad de viajar para visitar clientes, participar en ferias y eventos que ayuden a la expansión de la empresa.</p>
        <p>En PRESIK, tienes la posibilidad de una carrera con ascensos según los resultados, impactando pequeñas, medianas y grandes empresas del país. Estarás aprendiendo sobre nómina, ventas, marketing, software, compras, CRM, inventarios y mucho más. Además, recibirás entrenamiento en ventas, manejo de software y autonomía para trabajar con el área de marketing.</p>
        <h3 h3 class="font-semibold my-4">Queremos:</h3>
        <p>Una persona altamente proactiva, audaz para ganar clientes B2B, con liderazgo y mentalidad creativa orientada a startups. Debe tener capacidad para idear estrategias de venta, ser apasionada por las ventas, con excelente presentación personal, habilidades de relacionamiento asertivo con el cliente y negociación.</p>
        <h3 class="font-semibold my-4">Funciones:</h3>
        <ul class="my-4 list-disc list-inside">
          <li>Contactar prospectos y clientes.</li>
          <li>Elaborar cotizaciones.</li>
          <li>Liderar las ventas de la empresa.</li>
          <li>Hacer presentaciones comerciales a los potenciales clientes.</li>
          <li>Hacer seguimiento a las negociaciones con los potenciales clientes.</li>
          <li>Gestionar el envío de contratos y soportes requeridos para las negociaciones.</li>
          <li>Velar por el cumplimiento de metas de ventas.</li>
          <li>Apoyar al área de Marketing en campañas publicitarias y estrategias de marca.</li>
          <li>Llevar a la empresa a la expansión en nuevos mercados.</li>
        </ul>
        <p><strong>Tener en cuenta:</strong> Trabajo presencial en Bucaramanga; si estás en otra ciudad, se requiere disponibilidad para radicarse en Bucaramanga. Debe tener disponibilidad para viajar a diferentes ciudades del país continuamente.</p>
        <p class="mt-4"><strong>Ofrecemos:</strong> Salario base $2.300.000 + Prestaciones de ley + comisiones. Posibilidades de ascenso según cumplimiento de resultados.</p>
        <p>CV enviar a: <a href="mailto:gerente@presik.com">gerente@presik.com</a></p>
      `,
    },
   
  ];

  const jobsCustomer = []
  
  export  {jobs, jobsCustomer}