import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import PageHeroStandard from "Components/PageHeroStandard";

import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import dashImg1 from "Assets/images/dashboard-1.png";
import dashImg2 from "Assets/images/dashboard-2.png";
import dashImg3 from "Assets/images/dashboard-3.png";
import dashImg4 from "Assets/images/dashboard-4.png";
import "./Dashboard.css";

const data = [
  {
    image: dashImg3,
    title: "manager_report",
    text: "manager_report_desc",
    color: "gray",
  },
  {
    image: dashImg4,
    title: "payment_approval",
    text: "payment_approval_desc",
    color: "white",
    // width: 300,
  },
  {
    image: dashImg1,
    title: "sales_report",
    text: "sales_report_desc",
    color: "gray",
  },
  {
    image: dashImg2,
    title: "purchase_approval",
    text: "purchase_approval_desc",
    color: "white",
  },
];

function DashboardPage() {
  const { t } = useTranslation();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  function getImage(d, idx) {
    return (
      <div
        className="p-10 overflow-hidden relative"
        key={idx}>
        <img
          key={idx}
          className="dashboard-img max-h-96 "
          src={d.image}
          alt="dashboardimage"
          width={d.width}
        />
      </div>
    );
  }

  function getDescription(d, idx) {
    return (
      <div
        id={d.title}
        className="p-10 my-10 md:my-2 w-3/6"
        key={idx}>
        <div className="mt-10 w-6/6">
          <h2 className="text-gray-700 font-medium text-4xl">
            {t(`dashboard.${d.title}`)}
          </h2>
          <p className="text-3xl text-gray-600 mt-10">{t(`dashboard.${d.text}`)}</p>
        </div>
      </div>
    );
  }

  function getRow(d, idx) {
    return (
      <div 
        className="m-1 overflow-hidden flex-nowrap justify-center flex flex-col md:flex-row px-20 gap-10"
        id={`row-${d.color}`}
        key={idx}>
        {d.color === "gray"
          ? [getImage(d, idx), getDescription(d, idx)]
          : [getDescription(d, idx), getImage(d, idx)]}
      </div>
    );
  }

  return (
    <div className="dashboard-page">
      <PageHeroStandard>

      <PageTitle title="dashboard.title" />
      <PageDescription text="dashboard.content" />
      </PageHeroStandard>
      <div className="grid">{data.map((d, idx) => getRow(d, idx))}</div>
    </div>
  );
}

export default DashboardPage;
