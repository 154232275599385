import React, { Fragment, useState } from "react";
import PureModal from "./PureModal";
import ImgModal from "Assets/images/future.png";
import { PhoneArrowDownLeftIcon } from "@heroicons/react/24/solid";

const STYLE_FIELD = "flex flex-col"
const STYLE_LABEL = "block text-sm font-medium leading-6 text-gray-900"
const STYLE_INPUT = "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

const FormModal = () => {
  const [open, setOpen] = useState(false);
  const Form = () => {
    return (
      <form className="w-3/6 ">
        <div className="w-full p-8 space-y-5">
            <p className="">Envianos tus datos y muy pronto te contactaremos</p>
        <div className={STYLE_FIELD}>
          <label className={STYLE_LABEL}>Nombre</label>
          <input type="text" className={STYLE_INPUT} />
        </div>
        <div className={STYLE_FIELD}>
          <label className={STYLE_LABEL}>Empresa</label>
          <input type="text" className={STYLE_INPUT} />
        </div>
        <div className={STYLE_FIELD}>
          <label className={STYLE_LABEL}>Telefono</label>
          <input type="text" className={STYLE_INPUT} />
        </div>
        <div className={STYLE_FIELD}>
          <label className={STYLE_LABEL}>Email</label>
          <input type="text" className={STYLE_INPUT} />
        </div>
        <div>
          
        </div>
        <button className="font-roboto w-max uppercase flex gap-4 items-center text-black lg:text-xl mt-8 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black">Enviar</button>
     
        </div>
        
      </form>
    );
  };

  const ImageLeft = () => {
    return (
      <div className="w-3/6">
        <img src={ImgModal} className="w-full h-full object-cover" />
      </div>
    );
  };

  const openModal = (e) => {
    e.preventDefault()
    setOpen(true);
  };

  return (
    <Fragment>
      <div>
        <a
          className="font-roboto w-max uppercase flex gap-4 items-center lg:text-xl mt-8 md:ring-1 md:text-bluePresik hover:bg-yellow-300  text-bg-bluePresik text-base bg-yellow-300 md:ring-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black"
          href="https://c1.presik.com/app/PRESIKSAS/crm_form_prospect"
          // onClick={(e) => openModal(e)}
        >
          Contactar{" "}
          <PhoneArrowDownLeftIcon className="md:h-8 h-5 hover:scale-125 text-bg-bluePresik md:text-bluePresik" />
        </a>
      </div>
    {/* <PureModal open={open} onClose={() => setOpen(false)} width="sm:w-5/6 md:max-w-5xl">
        <div className="flex min-h-[600px]">
          <ImageLeft />
          <Form />
        </div>
      </PureModal> */}
    </Fragment>
  );
};

export default FormModal;
