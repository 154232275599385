import React, { useState, useEffect } from 'react';

// Componente para renderizar palabras con HTML embebido
const WordComponent = ({ text }) => {
    return (
        <span dangerouslySetInnerHTML={{ __html: text }} />
    );
};

const TextAnimation = ({arrayText = []}) => {
     
    const [currentWord, setCurrentWord] = useState(arrayText[0]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentWord(prevWord => {
                const currentIndex = arrayText.indexOf(prevWord);
                const nextIndex = (currentIndex + 1) % arrayText.length;
                return arrayText[nextIndex];
            });
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <span className="text-animation">
            <span className="fade-in lg:text-6xl text-4xl">
                <WordComponent text={currentWord} />
            </span>
        </span>
    );
};

export default TextAnimation;
