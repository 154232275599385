import React from "react";
import { useTranslation } from "react-i18next";
import "aos/dist/aos.css";
// import Aos from "aos";

import PageStandard from "Components/Page";
import PageBanner from "Components/PageBanner";
import docImg from "Assets/images/documentation.png";
import documentImg from "Assets/images/paper.png";
import youtubeImg from "Assets/images/youtube_logo.png";
import data from "./data_resources";
import "./Resources.css";
import PageHeroStandard from "Components/PageHeroStandard";

export default function Documentation() {
  const { t } = useTranslation();

  function getCardDoc(item) {
    let image = documentImg;
    if (item.kind === "video") {
      image = youtubeImg;
    }
    return (
      <a href={item.link} target="_blank" rel="noreferrer">
        <div className="bg-white shadow-md rounded-md my-5 relative overflow-hidden flex justify-between px-5 h-24 cursor-pointer hover:bg-gray-200 duration-150">
          <img src={image} alt="document" className="w-10 h-10 mt-4" />
          <h2 className="mt-4 w-3/6 text-gray-600 text-base text-center font-semibold">
            {t(`resources.${item.name}`)}
          </h2>
        </div>
      </a>
    );
  }

  return (
    <PageStandard>
      <PageHeroStandard backgroundHero="bgBlack" rounded={true}>

      <PageBanner
        title="resources.title"
        style={_style}
        description="resources.description"
      />
      </PageHeroStandard>
      <div className="container mx-auto py-20">
        {data.map((d, i) => (
          <div key={i} className="mb-20">
            <div key={i} className="resources-row-header flex items-center">
              <img src={d.icon} alt="resources" className="resources-header-icon" />
              <h1 className="resources-header">{t(`resources.${d.name}`)}</h1>
            </div>
            <div key={i} className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {d.items.map((doc, j) => (
                <div key={j} className="resources-row-col">
                  {getCardDoc(doc)}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </PageStandard>
  );
}

const _style = {
  backgroundImage: `url(${docImg})`,
};
