import React from "react";
import CarouselIntegration from "Home/CarouselIntegration";

const Partners = () => {
  return (
    <div className="space-y-8 overflow-hidden">
       <h2 className="font-lato text-blue-presik md:text-6xl text-4xl uppercase font-semibold  text-center">Integraciones</h2>
       <p className="font-roboto text-center text-lg md:text-2xl text-gray-700 font-light " style={{color: "rgb(22, 78, 99"}}>Nos integramos con tu página web, aplicaciones de terceros y tiendas en línea. ¡Aceptamos cualquier reto! 😉  </p>
    
        <CarouselIntegration />

    </div>
  );
};

export default Partners;
