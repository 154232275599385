import React from 'react'

const Section = ({children, spaceX= "px-5", spaceTop = "py-16 md:py-40", bgColor = "bg-transparent", height="h-auto", bgMap= false, overFlow = false, marginBottom = "mb-0", fullContainer = false, style= null}) => {
  return (
    <section className={`relative ${spaceX} md:px-0 ${spaceTop} ${bgColor} ${height} ${marginBottom} ${overFlow ? "overflow-hidden": "overflow-visible"}`}>
      {bgMap &&(
        <div className='w-screen h-full absolute -top-28 left-0 bgCountries'></div>
      )}
      
        <main className={`${fullContainer ? "w-screen" : "container"} ${style} mx-auto z-10 `}>
         {children}

        </main>
    </section>
  )
}

export default Section