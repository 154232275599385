import workflowImg from "Assets/images/workflow.png";
import basicGuidesImg from "Assets/images/basic_guides.png";
import accountGuidesImg from "Assets/images/account_guides.png";
import modulesGuidesImg from "Assets/images/modules_guides.png";

export default [
  {
    name: "tryton_workflows",
    icon: workflowImg,
    items: [
      {
        name: "workflow_global",
        link: "https://drive.google.com/file/d/1WIs2BuFh5kzpMYEykEcvgCEtZKpgv3Oe/view",
      },
      {
        name: "workflow_purchase",
        link: "https://drive.google.com/file/d/1-n-5Hk-ONafRa-YCIrZ8yIwOLjakZYPs/view",
      },
      {
        name: "workflow_sales",
        link: "https://drive.google.com/file/d/14ZkUBbIRKwcFzZndmGdwdchW4BT9BBLR/view",
      },
      {
        name: "workflow_stock",
        link: "https://drive.google.com/file/d/1S2dXNxX4FFNuOYWoPfAlkJZg_dgNiJ7e/view",
      },
      {
        name: "workflow_payroll",
        link: "https://drive.google.com/file/d/1xf6-fGm-OHHLMGcDOvaImwKkiWSLEGJT/view",
      },
    ],
  },
  {
    name: "basic_guides",
    icon: basicGuidesImg,
    items: [
      {
        name: "guide_party",
        link: "https://drive.google.com/file/d/11AGIGGgD6Lpj-4F8bK786wTctFxY9lFr/view",
      },
      {
        name: "guide_product",
        link: "https://drive.google.com/file/d/1Dqg-u01WBJdf-cWbaVvQvDGPDNifkPko/view",
      },
      {
        name: "guide_user_management",
        link: "https://drive.google.com/file/d/1cPjdsvrXdJewPrj2IZNyls2X71HJP51i/view",
      },
    ],
  },
  {
    name: "electronic_document_guides",
    icon: basicGuidesImg,
    items: [
      {
        name: "guide_configuration_electronic_invoice",
        link: "https://drive.google.com/file/d/1tnbt-W70wpKky46Gm3BhfZHmrJKmHDWu/view",
      },
      {
        name: "guide_electronic_invoice",
        link: "https://drive.google.com/file/d/1AFqkbveE4BbDGlWsIYU7pctUMN44JGOo/view",
      },
      {
        name: "guide_electronic_credit_note",
        link: "https://drive.google.com/file/d/1YJrjOBmOdvGdupK_A2WqZ9_QrZZABp1z/view",
      },
      {
        name: "guide_electronic_document_configuration",
        link: "https://drive.google.com/file/d/1EFvsT7Gqkkb3yJqVPCvYRl6d2fcXduWr/view",
      },
      {
        name: "guide_supplier_support_document",
        link: "https://drive.google.com/file/d/1ef8IsuI8JK4JDvaZh7oSEUM0yNWsk-mI/view",
      },
      {
        name: "guide_electronic_payroll_authorization",
        link: "https://drive.google.com/file/d/1XH9C7imTmcarIIJKr7X1AGVl7Phzt1SX/view",
      },
      {
        name: "guide_electronic_payroll",
        link: "https://drive.google.com/file/d/1QZcg2Sr20H1SWEn6exyFZys2-nH0ZrxL/view",
      },
    ],
  },
  {
    name: "account_guides",
    icon: accountGuidesImg,
    items: [
      {
        name: "guide_tax_creation",
        link: "https://drive.google.com/file/d/1YZi4gs7RdXpOYaIMliGZDeKKNPv_g0EK/view",
      },
      {
        name: "guide_account_creation",
        link: "https://drive.google.com/file/d/1c89uOB_sknLl1Mderf5hjNPaKaOXgyRi/view",
      },
      {
        name: "guide_asssets_creation",
        link: "https://drive.google.com/file/d/1ivjO50hmcv7d-u1x8WD71p-VZinbmbXi/view",
      },
      {
        name: "guide_voucher_creation",
        link: "https://drive.google.com/file/d/1fNyiPaTr1poniXj2Lr5_3ACs5BxJumuM/view",
      },
      {
        name: "guide_fiscalyear",
        link: "https://drive.google.com/file/d/1IwDQ3RsD44Talavqt-La4HHf32faWu8w/view",
      },
      {
        name: "guide_accounting_close",
        link: "https://drive.google.com/file/d/1aU46D9ppTN9gcvsjFXeFWg6y8a7q0M3_/view",
      },
      {
        name: "guide_account_reports",
        link: "https://drive.google.com/file/d/1RPlPnIf-l5ktjOr7pm5Oj7Nnx9lngWVK/view",
      },
      {
        name: "guide_exogena",
        link: "https://drive.google.com/file/d/1Er8w0WV6iE0uI-zXPSrKAoVZKRfOYXWV/view",
      },
      {
        name: "guide_invoice_receipt",
        link: "https://drive.google.com/file/d/1sHcnUeVrXsEW_50iHSYy5WNw_HKYXKoP/view",
      },
      {
        name: "guide_taxes_certificate",
        link: "https://drive.google.com/file/d/1i2n1kFq8U_8jS4PPJbHCASIHH4jhTARv/view",
      },
    ],
  },
  {
    name: "modules_guides",
    icon: modulesGuidesImg,
    items: [
      {
        name: "guide_employees",
        link: "https://drive.google.com/file/d/1fCsjuVbAzcb5D8Y3gfC0GEXt_agVxMc4/view",
      },
      {
        name: "guide_payroll",
        link: "https://drive.google.com/file/d/1Z_tPTdyw5uvOPbBids9wp08a3_SyZw_v/view",
      },
      {
        name: "guide_liquidation_employees",
        link: "https://drive.google.com/file/d/1dtqaQCfkJKEDZ8DNkdPlMOPPZn_I36lq/view",
      },
      {
        name: "guide_stock",
        link: "https://drive.google.com/file/d/1O7nT5yowQTSSPzwSOXOtNxOABN_5cAzo/view",
      },
      {
        name: "guide_shipment_internal",
        link: "https://drive.google.com/file/d/1GWqIOe9ZJv0zpkizGImOJc01VMNyjfPa/view",
      },
      {
        name: "guide_sales",
        link: "https://drive.google.com/file/d/1jETwmPAo8shXLBTZkEDHuSbkCf9-U1w1/view",
      },
      {
        name: "guide_account_statement_open",
        link: "https://drive.google.com/file/d/1Kim-N45MFOEK51jEimgqKRs-DQR5D_Zt/view",
      },
      {
        name: "guide_sale_w_electronic_invoice",
        link: "https://drive.google.com/file/d/1EYWxb3fb1e9KI_dMYBjtiHsNyhBW989B/view",
      },
      {
        name: "guide_purchases",
        link: "https://drive.google.com/file/d/1E2nVAu_QnY5RHYuA9ensBN-3xch_WlFJ/view",
      },
      {
        name: "guide_pos_sale_retail",
        link: "https://drive.google.com/file/d/15nA18GfwFBBg7xNF8sdlcUFOkIF4KieU/view",
      },
      {
        name: "guide_pos_sale_rest",
        link: "https://drive.google.com/file/d/15PQ72yTsbZGX58XbrBQVTg36KAF8_WVo/view",
      },
    ],
  },
];
