import React from 'react';
import dividerBlue from "../Assets/images/divider-blue.svg";
import dividerGray from "../Assets/images/divider-gray.svg";
import dividerYellow from "../Assets/images/divider-yellow.svg";

const DividerWaveSection = ({ colorWabe = "blue", colorBg = "transparent", colorText = "gray", position = "top", reverse = false, height = "h-32" }) => {

  let styles = "md:w-full md:h-80 transform ";
  styles = styles.concat(styles," ", height)
  let dividerWave;
  
  if (reverse === true){
    styles = styles.concat(styles, " rotate-180") 
  }
  if (colorWabe == "blue"){
    dividerWave = dividerBlue;
  }
  if (colorWabe == "yellow"){
    dividerWave = dividerYellow;
  }
  if (colorWabe == "gray"){
    dividerWave = dividerGray;
  }
  if (position == "absolute"){
     styles =  styles.concat(styles, " absolute left-0 top-0")
  }

  
  return (
    <div className='overflow-hidden '>
        <img src={dividerWave} className={styles} alt="Divider Blue" />
    </div>
  );
};

export default DividerWaveSection;
