import imgHouseKeeping from "Assets/images/apps/Housekeeping.png";
import imgPurchase from "Assets/images/apps/Aprobación de compras.png";
import imgRack from "Assets/images/apps/RACK.png";
import dashboardImg from "Assets/images/aplicativo-dashboard.png";
import precheckIn from "Assets/images/apps/preck-chein.png";

const infoServicesHotel = {
  "pms": {
    "services": 
      {
        "Gestión Operativa": [
      {
        "title": "Módulo de reservas / folios",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Inventarios",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Plan tarifario Mutiple",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Auditoría nocturna",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Bitácora de novedades",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Módulo de servicios (lavandería, desayunos, SPA, etc.)",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Impresión de tarjeta de registro (español e inglés)",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Gestión de pasadías / auditorios",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Bloqueo de habitación",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Manejo de pre cuenta y facturación anticipada",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Contabilidad automática de comisiones de canales",
        "desc": "",
        "icon": "check"
      }
      ,
      {
        "title": "Reportes migración, reservas, ocupación, huéspedes e ingresos",
        "desc": "",
        "icon": "check"
      }
      
    ],
    "Gestión Producción/ Cocina": [
      {
        "title": "Costos de producción",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Plan de desayunos (App)",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Lista de materiales (BOM)",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Forecast de insumos semanal",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Integración con ocupación y ventas",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Orden de producción (catering)",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Integración con stock",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Kardex y reporte de consumos de materias primas",
        "desc": "",
        "icon": "check"
      },

    ],
    "Gestión de Mantenimiento": [
      {
        "title": "Hoja de vida de equipos",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Órdenes de trabajo",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Plan de mantenimiento",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Programa de mantenimiento ",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Calibración de equipos",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Costos integrados a compras ",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Control de métricas de equipos",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Informes de cumplimiento y costos de mantenimiento",
        "desc": "",
        "icon": "check"
      },

    ],
     
  },
    "desc": "Nuestro sistema de gestión hotelera cumple cubre todo los procesos operativos y estratégicos del negocio, desde el web check-in, hasta la auditoría y su respectiva facturación. Disfrute de la integración perfecta con el motor de reservas y el channel manager.",
    "alt": "Pms tecnologia para hoteles "
  },

  "erp": {
    "services": {
      "Capítal humano y nómina":[
      {
        "title": "Hoja de vida de empleados",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Generación automática de nómina",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Registro de asistencia y liquidación de horas extras.",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Notificaciones de contratación",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Liquidación de contrato, primas e intereses de cesantías",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Módulo de préstamos",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Novedades de nómina",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Nómina electrónica",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Informes UGPP",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
    ],
    "Gestión de compras":[
      {
        "title": "Gestión de proveedores",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Solicitudes y requisiciones ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Formato de cotización",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Orden de compra por correo ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Gestión de aprobación de compras",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Seguimiento a mercancía ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Generación de compra sugerida",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Informes y costos históricos ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Auditoría de compras e informes por centros de costos ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
    
    ],
    "Gestión de contable": [
      {
        "title": "Plan de cuentas  ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Centros de costos",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Centros de operaciones ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Multi-moneda",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Cierre de periodos y año fiscal",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Cierre de periodos y año fiscal ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Contabilidad automática de activos fijos",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Información Exógena",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Informes de cartera",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "Módulo de tesorería ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
    ],
    "Gestión de inventario": [
      
      {
        "title": "Manejo de múltiples bodegas",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "Inventarios por proveedor ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Gestión de devoluciones",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "Inventario histórico",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Ajustes de inventario",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "Automatización de máximos y mínimos",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Traslados internos",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "Requisiciones automáticas",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Informe de rotación, bodegas y kardex",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "Integración con códigos de barras/ QR",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      }
      ,
      {
        "title": "Control por lotes y fechas de vencimiento",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "App auditoría de inventarios",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
    ],
    "Ventas": [
      {
        "title": "Cotizaciones y orden de pedido",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "Pesupuesto de ventas",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Listas de precios por cliente",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "Causación de comisiones automáticas ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Factura electrónica directo a la DIAN",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      
      {
        "title": "Reporte de ventas por agente, cliente, clase, categoría y ambiente",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Notas crédito electrónica ",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
      {
        "title": "Traslado de cargos a folios",
        "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "icon": "check"
      },
    ]
  },
    "desc": "Con nuestro sistema ERP completamente integrado, podrás gestionar eficientemente todos los aspectos operativos y financieros de tu empresa desde un solo entorno. Automatiza la generación de nómina, controla la asistencia y las horas extras de los empleados, gestiona préstamos y liquidaciones de contrato, y accede a informes detallados al instante. Nuestra interfaz intuitiva está diseñada para que los usuarios puedan optimizar procesos y tomar decisiones estratégicas sin complicaciones.",
    "alt": "ERP para hoteles"
  },

  "pos": {
    "services": {
      "Caracteristicas POS": [
      {
        "title": "Arqueo de caja",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Facturación electrónica",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Panel de domicilios",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Múltiples formas de pago",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Manejo de cortesía",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Integración directa con contabilidad ",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "División de cuenta",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Historial de compra por usuario",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Manejo de descuentos y bonos",
        "desc": "",
        "icon": "check"
      }
      ,
      {
        "title": "Manejo de anticipos de reservas",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "App - carta de restaurante",
        "desc": "",
        "icon": "check"
      }
      ,
      {
        "title": "App - toma de pedidos de meseros",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Segmentación de venta por canal ",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Transferencia a folios o eventos",
        "desc": "",
        "icon": "check"
      }
    ]},
    "desc": "Con nuestro sistema POS totalmente integrado, podrás ágilmente desde tu ambiente facturar, realizar cierre y arqueo de caja, controlar las mesas , registrar el número de personas por mesa, imprimir las comandas, nuestra interfaz intuitiva está diseñada para que los usuarios trabajen sin complicaciones.",
    "alt": "Software POS"
  },

  "crm": {
    "services": {
      "Caracteristicas CRM": [
      {
        "title": "Módulo de leads / opportunities",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Base de datos de prospectos",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Módulo de peticiones, quejas y reclamos",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Envío de notificaciones email y SMS (personalizado y/o masivos)",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Módulo de encuestas de satisfacción del cliente",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Reportes de ventas por canal, agente y centros de costos",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Reporte de negocios ganados por vendedor",
        "desc": "",
        "icon": "check"
      },
      {
        "title": "Forecast predictivo de ventas",
        "desc": "",
        "icon": "check"
      }
    ]},
    "desc": "Nuestro CRM facilita el cumplimiento de la estrategía comercial, la administración, gestión agíl y centralizada de tus prospectos. Facili Nuestro CRM facilita el cumplimiento de la estrategía comercial, la CRM - ta el seguimiento de las negocaciones con el cliente y las tareas asignadas a los agentes comerciales.",
    "alt": "Crm para hoteles"
  }
}

const applications = [
  {
    id:1,
    title: "Rack",
    desc: "Herramienta hotelera que representa todas las habitaciones con el objetivo de controlar la disponibilidad y ocupación permanentemente el estado de las mismas.",
    image: imgRack,
    features: [{
      title: "Gestionar las reservas",
      desc: "Realizar un seguimiento de las llegadas y salidas de los huéspedes."
    },
    
    {
      title: "Controlar la disponibilidad de las habitaciones",
      desc: "Seguimiento de las habitaciones disponibles y ocupadas"
    },
    {
      title: "Realizar un seguimiento de las llegadas y salidas de los huéspedes",
      desc: ""
    }]
  },{
    id: 2,
    title: "Check-in 30 seg",
    desc: "Tus huéspedes quieren menos tiempo en la recepción y más tiempo para vivir experiencias.",
    image: precheckIn,
    features: [
      {
        title: "Registro Ágil",
        desc: "Permite a los huéspedes realizar el check-in en solo 30 segundos, reduciendo el tiempo de espera."
      },
      {
        title: "Validación Rápida",
        desc: "Automatiza la verificación de documentos y datos para agilizar el proceso."
      },
      {
        title: "Integración Perfecta",
        desc: "Sincroniza el check-in con el sistema de gestión del hotel para mantener todo actualizado."
      }
    ]
  }
,  
{
  id: 3,
  title: "Housekeeping",
  desc: "Administra el orden y mantenimiento de las habitaciones con un módulo simple e intuitivo.",
  image: imgHouseKeeping,
  features: [
    {
      title: "Asignación de Tareas",
      desc: "Organiza y asigna tareas de limpieza de manera eficiente para el personal."
    },
    {
      title: "Estado de las Habitaciones",
      desc: "Monitorea el estado actual de cada habitación para asegurar que estén listas para los huéspedes."
    },
    {
      title: "Alertas en Tiempo Real",
      desc: "Recibe notificaciones instantáneas sobre el estado de las tareas de limpieza."
    }
  ]
},

{
  id: 4,
  title: "Aprobación de compras",
  desc: "Gestiona y controla las órdenes internas del hotel, permitiendo la revisión y aprobación eficiente de las solicitudes de compra.",
  image: dashboardImg,
  features: [
    {
      title: "Flujo de Aprobación Automatizado",
      desc: "Optimiza el proceso de aprobación con flujos automáticos, asegurando que cada solicitud pase por los niveles correctos de autorización."
    },
    {
      title: "Historial de Compras",
      desc: "Accede fácilmente al historial completo de órdenes aprobadas y rechazadas, facilitando la trazabilidad y auditoría de compras."
    },
    {
      title: "Notificaciones en Tiempo Real",
      desc: "Recibe alertas instantáneas cuando se requiere tu aprobación, manteniéndote al tanto de cada paso en el proceso de compra."
    }
  ]
}


]

export { infoServicesHotel, applications }
