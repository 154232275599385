import React from "react";
import { useTranslation } from "react-i18next";

import "./Page.css";

function PageTitle(props) {
  const { t } = useTranslation();
  const {textAling = "text-center", color = "text-white"} = props

  let colorStyle = "white";
  if (props.color) {
    colorStyle = props.color;
  }

  return (
    <div className="page-standard-row">
      <div className="page-title-container">
        <h1 className={`font-lato pt-12 md:mt-8  lg:text-6xl text-4xl uppercase font-semibold ${color} ${textAling} `}  >
          {t(props.title)}
        </h1>
      </div>
    </div>
  );
}

export default PageTitle;
