import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";
import Section from "Components/Section";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import DashboardSection from "./DashboardSection";
import CustomersSection from "./CustomersSection";
import Asesora from "../Assets/images/asesorapresik.webp";
import Modules from "./Modules";
import dataModules from "Home/data";
import OtherModules from "./OtherModules";
import PageStandard from "Components/Page";
import PageHeroStandard from "Components/PageHeroStandard";
import PageDescription from "Components/PageDescription";
import CounterExperience from "Components/CounterExperience";
import Partners from "Components/Partners";
import { Link } from "react-router-dom";
import Blog from "Components/Blog";
import TextAnimation from "TextAnimation";

const dataCounter = {
  countries: { name: "Paises", number: 4 },
  customers: { name: "Clientes", number: 130 },
  invoices: {
    name: "Facturas emitidas por nuestros clientes",
    number: "90k",
  },
  integrations: { name: "Usuarios activos", number: 520 },
};
const words = [
  "MRP",
  "SMP",
  "PMS <span class='text-yellow-500'>+</span> POS",
  "POS",
  "LMS"
];

export default function Home() {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <PageStandard>
      <PageHeroStandard backgroundHero="image" image={Asesora}>
        <h1 className="pt-12 md:mt-8 text-white max-w-2xl lg:text-6xl text-4xl uppercase mb-8 font-lato">
          <span className="font-extralight ">
            Software Presik{" "}
            <span className="font-semibold block">
              ERP <span className="text-yellow-300">+</span> CRM{" "}
              <span className="text-yellow-300">+</span>  <TextAnimation arrayText={words} /> 
            </span> 
           
             </span>
        </h1>

        <PageDescription
          text="solutions.description_title"
          textAling="text-left"
        />
        <Link
          className="font-roboto w-max uppercase flex gap-4 items-center text-black lg:text-xl mt-8 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 ease-linear transition-all transform duration-100 hover:text-black"
          to="/about_us/contact"
        >
          {" "}
          Contáctanos{" "}
          <ArrowLongRightIcon className="h-8 text-black hover:scale-125" />
        </Link>
      </PageHeroStandard>

      {/* <HeroSection /> */}
      <Section screen="full" columns="2" spaceTop="py-0">
        <h2 className="font-lato font-light lg:text-3xl  text-2xl lg:w-full relative text-bg-bluePresik leading-none max-w-2xl mx-auto uppercase text-center">
          Con nuestros
          <span className="font-semibold bg-yellow-300 text-bg-bluePresik uppercase  px-2 mx-2">
            módulos base
          </span>{" "}
          lleva tu empresa al{" "}
          <span className="font-semibold  text-bg-bluePresik ">
            siguiente nivel
          </span>
        </h2>

        <p
          className="lg:text-2xl text-lg  font-light pb-10 md:pb-2 text-center mt-8 max-w-6xl mx-auto font-roboto"
          style={{ color: "#164e63" }}
        >
          Tryton ERP es una plataforma inteligente que mejora tus procesos
          contables, ventas, gestión de compras, recursos humanos, presupuestos,
          inventarios, centros de costos, producción y gestión de clientes.
        </p>
        <OtherModules data={dataModules} lang={t}></OtherModules>
      </Section>
      {/* <DividerWaveSection /> */}

      <Modules data={dataModules} />
      <DashboardSection bgColor="dark" textColor="text-white" />
      <Section overFlow={true}>
        <CustomersSection />
      </Section>

      <Section
        bgColor="bg-gradient-to-tr from-yellow-300 via-yellow-300 to-yellow-200"
        bgMap={true}
        overFlow={true}
      >
        <CounterExperience
          mode="yellow"
          dataCounter={dataCounter}
          columns={"grid-cols-4"}
        />
      </Section>
      <Section
        spaceTop="pt-16 md:pt-40"
        >
        <Partners mode="lite" />
      </Section>
      <Section spaceTop="pb-16 md:py-16 mb-10">
        <Blog />
      </Section>
    </PageStandard>
  );
}
