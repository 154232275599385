import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="text-center">
      <div className="flex justify-center space-x-4">
        {Object.keys(timeLeft).length ? (
          Object.entries(timeLeft).map(([unit, value]) => (
            <div key={unit} className="group shadow-sm rounded-md px-7 py-3 cursor-pointer  pb-2 flex flex-col justify-center  w-40 items-center space-y-1 active:scale-95 active:shadow-x bg-bluePresik text-white">
              <div className="text-6xl font-semibold">{value}</div>
              <div className="text-xs font-semibold uppercase group-hover:text-yellow-300 pb-2 text-yellow-300">{unit}</div>
            </div>
          ))
        ) : (
          <div className="p-4 bg-red-500 text-white rounded-lg">
            <div className="text-2xl">Se termino!</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountdownTimer;
