import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

import PageStandard from "Components/Page";
import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import "./ElectronicPayroll.css";
import ElectronicPayrollImg from "Assets/images/electronic_payroll.svg";
import PageHeroStandard from "Components/PageHeroStandard";

function ElectronicPayroll() {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <PageStandard buttonContactUs={true} >
      <PageHeroStandard>

      <PageTitle title="electronic_payroll.title" color="dark" />
      <PageDescription text="electronic_payroll.content" color="dark" />

      </PageHeroStandard>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 items-center justify-center container mx-auto space-x-10">
        <div data-aos="fade-right" className="md:col-start-1 col-span-1 mb-8 md:mb-0">
        <h2 className="font-semibold lg:text-5xl text-4xl lg:w-full relative text-gray-600 leading-none">

            {t("electronic_billing.why_electronic_billing")}
          </h2>
          <p className="lg:text-2xl text-lg text-gray-700 font-light pb-2  mt-8">
            {t("electronic_billing.cause_use_electronic_billing")}
          </p>
        <h2 className="font-semibold lg:text-5xl text-4xl lg:w-full relative text-gray-600 leading-none mt-5">

            {t("electronic_billing.why_prefer_us")}
          </h2>
          <p className="lg:text-2xl text-lg text-gray-700 font-light pb-2  mt-8">
            {t("electronic_billing.cause_prefer_us")}
          </p>
        </div>
        <div data-aos="fade-right" className="md:col-start-2 col-span-1">
          <img src={ElectronicPayrollImg} alt="Electronic Billing" className="w-full" />
        </div>
    </div>
    </PageStandard>
  );
}

export default ElectronicPayroll;
