import React from "react";
import { useTranslation } from "react-i18next";

import "./PageBannerDesc.css";

function PageBannerDesc(props) {
  const { t } = useTranslation();

  let colorStyle = "white";
  if (props.color) {
    colorStyle = props.color;
  }
  return (
    <div className="mt-10 ">
      <p  className="lg:text-2xl text-xl font-light text-center max-w-2xl mx-auto text-white">
        {t(props.text)}
      </p>
    </div>
  );
}

export default PageBannerDesc;
