export default {
  presik: [
    ["question1", "reply1"],
    ["question2", "reply2"],
    ["question3", "reply3"],
    ["question4", "reply4"],
  ],
  tryton: [
    ["question5", "reply5"],
    ["question6", "reply6"],
    ["question7", "reply7"],
  ],
  electronic_billing: [
    ["question8", "reply8"],
    ["question9", "reply9"],
  ],
  electronic_payroll: [
    ["question10", "reply10"],
    ["question11", "reply11"],
  ],
};
