import accountingImg from 'Assets/images/solutions.jpeg';
import stockImg from 'Assets/images/stock.jpeg';
import salesImg from 'Assets/images/sales.jpeg';
import productionImg from 'Assets/images/production.jpeg';
import humanCapitalImg from 'Assets/images/human_capital.jpeg';
import purchaseImg from 'Assets/images/purchase.jpeg';
import crmImg from 'Assets/images/crm.jpeg';
import maintenanceImg from 'Assets/images/maintenance.jpeg';
import ecommerceImg from 'Assets/images/ecommerce.jpeg';

export default [{
        name: 'accounting',
        icon: 'calculator',
        desc: 'accounting_description',
        shortDesc: 'Automatiza contabilidad NIIF, reduzca tiempo transacciones.',
        color: 'yellow',
        img: accountingImg,
    },
    {
        name: 'stock',
        icon: 'dolly',
        desc: 'stock_description',
        shortDesc: 'Rastrea productos en sus almacenes desde el recibo hasta la entrega.',
        color: 'olive',
        img: stockImg,
    },
    {
        name: 'human_capital',
        icon: 'address card',
        desc: 'human_capital_description',
        shortDesc: 'Administra nómina, imprime contratos, genera nómina en minutos.',

        color: 'yellow',
        img: humanCapitalImg,
    },
    {
        name: 'purchase',
        icon: 'shipping fast',
        desc: 'purchase_description',
        shortDesc: 'Sigue tus solicitudes de cotización y reduce costos al agrupar pedidos.',

        color: 'teal',
        img: purchaseImg,
    },
    {
        name: 'sales',
        icon: 'chart line',
        desc: 'sales_description',
        shortDesc: 'Seguir órdenes de cotización a satisfacción del cliente.',

        color: 'teal',
        img: salesImg,
    },
    {
        name: 'production',
        icon: 'dolly flatbed',
        desc: 'production_description',
        shortDesc: 'Transformar materias en productos con lista y enrutamiento.',

        color: 'teal',
        img: productionImg,
    },
    {
        name: 'crm',
        icon: 'users',
        desc: 'crm_description',
        shortDesc: 'Convierte tus guías y oportunidades en ventas.',

        color: 'olive',
        img: crmImg,
    },
    {
        name: 'maintenance',
        icon: 'wrench',
        desc: 'maintenance_description',
        shortDesc: 'Ten control completo de activos, equipos, vehículos y dispositivos.',

        color: 'yellow',
        img: maintenanceImg,
    },
    {
        name: 'online_store',
        icon: 'globe',
        desc: 'online_store_description',
        shortDesc: 'stock_description_short',

        color: 'olive',
        img: ecommerceImg,
    },
];