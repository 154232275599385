import React from "react";
import { useTranslation } from "react-i18next";
import "aos/dist/aos.css";

import PageStandard from "Components/Page";
import PageBanner from "Components/PageBanner";
import docImg from "Assets/images/documentation.png";
/* import documentImg from "Assets/images/paper.png"; */
import data from "./data_documentation";
import PageHeroStandard from "Components/PageHeroStandard";
import "./Documentation.css";
import {
  ClipboardDocumentListIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";

export default function Documentation() {
  const { t } = useTranslation();

  function getCardDoc(doc) {
    return (
      <a href={doc.link} target="_blank" rel="noreferrer">
        <div className="shadow-md space-x-4 relative overflow-hidden bg-gray-100 rounded-lg flex justify-start align-middle items-center px-5 h-24 cursor-pointer hover:bg-gray-200 duration-150">
          {IconChild()}

          <h2 className=" text-gray-600 text-lg text-left font-medium">
            {t(`documentation.${doc.name}`)}
          </h2>
        </div>
      </a>
    );
  }
  const Icon = (i) => {
    return (
      <div key={i} className="flex  items-center align-middle -space-x-10">
        <span className="bg-yellow-300 h-14 w-14 rounded-full"></span>
        <ClipboardDocumentListIcon className="text-gray-950 w-14 h-14 " />
      </div>
    );
  };

  const IconChild = (i) => {
    return (
      <div className="flex  items-center align-middle -space-x-4 ">
        <span className="bg-blue-300 h-7 w-7 rounded-xl transform rotate-45 opacity-70"></span>
        <DocumentTextIcon className="text-gray-800 w-9 h-9 z-10 " />
      </div>
    );
  };
  return (
    <PageStandard>
      <PageHeroStandard backgroundHero="bgBlack" rounded={true}>
      <PageBanner
        title="documentation.title"
        style={_style}
        description="documentation.description"
      />
      </PageHeroStandard>
      <div className="container py-20 flex flex-col space-y-28 mx-auto ">
        {data.map((d, i) => (
          <div key={i} className="container w-screen md:w-11/12 flex justify-center flex-col mx-auto ">
            <div
              key={i}
              className="flex space-x-3 mb-8 justify-start items-center"
            >
              {/* <img src={d.icon} className="documentation-header-icon" alt="documentation" /> */}
              {Icon(i)}

              <h2 className="text-3xl  font-semibold text-gray-700">
                {t(`documentation.${d.name}`)}
              </h2>
            </div>
            <div
              key={i}
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4"
            >
              {d.items.map((doc, j) => (
                <div key={j} className="m-2">
                  {getCardDoc(doc)}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </PageStandard>
  );
}

const _style = {
  backgroundImage: `url(${docImg})`,
};
