import React, { useState } from "react";
import PageStandard from "Components/Page";
import {jobs} from "./data";
import Section from "Components/Section";
import { useParams } from "react-router-dom";
import PureModal from "Components/modal/PureModal";
import {
  BriefcaseIcon,
  ChevronDoubleDownIcon,
  ClockIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";

const SingleLandingWorkWith = () => {
  const { slug } = useParams(); 
  console.log(slug);
  
  const job = jobs.find((job) => job.slug === slug);
  console.log(job, " jasdjasjd");
  
  if (!job) {
    return <div>No se encontró la oferta laboral.</div>;
  }

  const JobContent = () => {
    return (
      <div className="max-w-7xl rounded-md  overflow-hidden divide-y divide-gray-300">
          <div className="bg-white p-8 shadow-md">
            <div className="space-y-4 md:w-[90%]">
              <div className="md:space-x-3 space-y-2 md:space-y-0 text-xl md:flex md:divide-x divide-gray-800 font-semibold">
                <span className=" flex items-start md:items-center space-x-2">
                  <CurrencyDollarIcon className="w-6" />
                  <span>{job?.salary}</span>
                </span>
                <span className="md:pl-3 flex items-start md:items-center space-x-2">
                  <BriefcaseIcon className="w-6" />

                  <span>{job.typeContract}</span>
                </span>
                <span className="md:pl-3 flex items-start md:items-center space-x-2">
                  <ClockIcon className="w-6" />

                  <span>{job.type}</span>
                </span>
                <span className="md:pl-3 flex items-start md:items-center space-x-2">
                  <MapPinIcon className="w-6" />
                  <span>{job.place}</span>
                </span>
              </div>
              <div dangerouslySetInnerHTML={{ __html: job.longDesc }} />
             
            </div>
          
          </div>
      </div>
    );
  };

  const ContentHero = ({ title, text, styleText = "lg:text-6xl text-4xl" }) => {
    return (
      <div
        className={
          "md:pt-10 flex flex-col justify-center mb-0 pb-10 pt-10  lg:pb-10 lg:px-0 px-5  mx-auto mt-16  p-2 bg-gray-800 w-full"
        }
      >
        <h2
          className={`font-lato text-white ${styleText} uppercase font-semibold text-center`}
        >
          {title}
        </h2>
        <p className="font-roboto lg:text-2xl text-lg text-white font-light md:pb-2 text-center md:mt-8 mt-5 max-w-6xl mx-auto">
          {text}
        </p>

        <ChevronDoubleDownIcon className="w-10 mx-auto text-white animate-pulse mt-4" />
      </div>
    );
  };

  return (
    <PageStandard color="bg-gray-200">
      <ContentHero
        title={job.title}
        text={job.shortDesc}
      />

      <Section spaceTop="py-10 md:py-20">
        <div className="md:flex justify-center items-start w-full md:space-x-10">
          <div className="md:w-[85%]">
            <JobContent />
          </div>
          <div className="md:w-[25%] bg-gray-800 text-white p-6 space-y-3  md:rounded-md shadow-md  sticky top-20 ">
            <h2 className="text-2xl font-semibold">Datos de contacto</h2>
            <ul className="space-y-2">
              <li className="flex space-x-3">
                <PhoneIcon className="w-4" />
                <span>{job.contact.phone}</span>
              </li>
              <li className="flex space-x-3">
                <EnvelopeIcon className="w-4" />
                <a href={`mailto:${job.contact.email}`}>{job.contact.email}</a>

              </li>
          
            
            </ul>
          </div>
        </div>
      </Section>
    </PageStandard>
  );
};

export default SingleLandingWorkWith;
