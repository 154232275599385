import React from "react";
import { useTranslation } from "react-i18next";
import { PhoneIcon, DevicePhoneMobileIcon} from "@heroicons/react/24/solid";
import PageStandard from "Components/Page";
import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import PageYellowImg from "Assets/images/page-yellow.png";
import "./Support.css";
import PageHeroStandard from "Components/PageHeroStandard";
import team from "Assets/images/teamtryton.jpeg"
export default function Support() {
  const { t } = useTranslation();

  return (
    <PageStandard buttonContactUs={false}>
      <PageHeroStandard backgroundHero="imageRoundHeroText" image={team}>

      <PageTitle title="contact_us.title" />
      <PageDescription text="contact_us.description" />

      </PageHeroStandard>
      <div class="container flex justify-center py-8 mx-auto">
        <div>xxzsdsada
        <ul class="w-1/2">
          <div className="flex gap-40 justify-between">
          <li className="my-2 gap-3 text-lg font-medium flex items-center">
            <i className="fi fi-ss-envelope text-base duration-100 "/>
            <a className="mb-2" href="mailto:consultor@presik.com">consultor@presik.com</a></li>

          <li className="my-2 gap-3 text-lg font-medium flex items-center">
          <i className="fi fi-ss-envelope text-base duration-100 "/>
            <a className="mb-2" href="mailto:coordinadordeproyectos@presik.com">coordinadordeproyectos@presik.com</a>
            </li>

          </div>
          <li className="my-2 text-lg font-medium flex gap-2 items-center">
          <DevicePhoneMobileIcon className="h-5"/>
            (+57) 694 5858
            </li>

          <li className="my-2 text-lg font-medium flex gap-2 items-center">
          <PhoneIcon className="h-5 transform -rotate-90"/>
            (+57) 321 426 9824
            </li>

          <li className="my-2 text-lg font-medium flex gap-2 items-center">
          <PhoneIcon className="h-5 transform -rotate-90"/>
            (+57) 302 324 8383
            </li>
        </ul>
        </div>
        <div>

        </div>
       
      </div>
    </PageStandard>
  );
}
