import React from "react";
import { useTranslation } from "react-i18next";
import dashboardImg from "Assets/images/aplicativo-dashboard.webp";
import "./DashboardSection.css";
import { BellAlertIcon, CheckIcon } from "@heroicons/react/24/solid";

export default function DashboardSection(props) {
  const { t } = useTranslation();

  const getIcon = () => {
    return (
      <div className="h-6 w-6  z-20 uppercase rounded-md bg-bluePresik transform rotate-45  group-hover:rotate-90 group-hover:bg-blue-600 transition-all duration-500 p-1">
        <CheckIcon className="W-12 transform text-white -rotate-45" />
      </div>
    );
  };

  return (
    <section
      id="dashboard-section"
      className="w-full bg-gradient-to-tr from-gray-600 via-gray-800 to-gray-900 overflow-hidden px-5 md:px-0"
    >
      <div className="container mx-auto md:py-40 py-10 pb-24 justify-center flex flex-col md:space-y-8 space-y-5 max-w-sm md:max-w-screen-2xl ">
        <h2 className="font-lato mt-8 text-white md:text-6xl text-4xl uppercase font-semibold  text-center">
          Dashboard
        </h2>

        <div>
          <div className="">
            <p className="text-center md:text-2xl text-lg text-white font-light pb-2 font-roboto">
              {t("home.how_manager")}
            </p>
          </div>
        </div>
        <div className="w-auto lg:w-10/12 md:px-10 lg:px-0  lg:mx-auto">
          <ul className="grid lg:grid-cols-4 grid-cols-2 lg:gap-6 gap-4 justify-center  lg:text-lg text-xs text-gray-600  font-roboto">
            
            <li className="flex space-x-1 cursor-pointer bg-yellow-300 lg:py-4 lg:px-5 px-2 py-3 uppercase shadow-md rounded-lg transform hover:bg-gray-200   hover:scale-105 ease-linear transition-all duration-100 ">
            {getIcon()}
              <a
                href="/solutions/dashboard#payment_approval"
                className="hover:text-black text-gray-700 lg:text-base pl-2"
              >
                {" "}
                Aprobación de Pagos
              </a>
            </li>
            <li className="flex space-x-1 cursor-pointer bg-yellow-300 lg:py-4 lg:px-5 px-2 py-3 uppercase shadow-md rounded-lg transform hover:bg-gray-200   hover:scale-105 ease-linear transition-all duration-100 ">
              {getIcon()}
              <a
                href="/solutions/dashboard#sales_report"
                className="hover:text-black text-gray-700 lg:text-base pl-2"
              >
                Reportes de Ventas
              </a>
            </li>
            <li className="flex space-x-1 cursor-pointer bg-yellow-300 lg:py-4 lg:px-5 px-2 py-3 uppercase shadow-md rounded-lg transform hover:bg-gray-200   hover:scale-105 ease-linear transition-all duration-100 ">
              {getIcon()}
              <a
                href="/solutions/dashboard#purchase_approval"
                className="hover:text-black text-gray-700 lg:text-base pl-2"
              >
                Aprobación de Compras
              </a>
            </li>
            <li className="flex space-x-1 cursor-pointer bg-yellow-300 lg:py-4 lg:px-5 px-2 py-3 uppercase shadow-md rounded-lg transform hover:bg-gray-200   hover:scale-105 ease-linear transition-all duration-100 ">
              {getIcon()}
              <a
                href="/solutions/dashboard#manager_report"
                className="hover:text-black text-gray-700 lg:text-base pl-2"
              >
                Indicadores de CRM
              </a>
            </li>
          </ul>
        </div>
        <div className="md:w-10/12 flex mx-auto w-12/12 pt-5 relative  ">
          <BellAlertIcon className=" right-[141px] text-gray-500 top-24 w-5 h-5  stroke-2 absolute  animate-ping z-40 hidden md:block" />

          <img
            className="mx-auto z-10 w-full rounded-md md:rounded-2xl shadow-lg relative shadow-white-presik"
            alt="dashboard presik"
            src={dashboardImg}
          />
          <div
            className="md:h-52 md:w-52 w-20 h-20 rounded-full bg-yellow-300 absolute md:-left-16  md:-bottom-8 -bottom-10 -right-5"
            data-aos="fade-down-left"
          ></div>

          <div
            className="md:w-4/12 w-3/4  shadow-lg z-10 rounded-lg bg-transparent-presik  absolute -bottom-12 md:-left-12 -left-5  md:text-2xl text-xs text-white md:py-5 md:px-9 py-3 px-5"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <span className="font-roboto">
              {" "}
              {t("home.title1")} <b> {t("home.title2")} </b>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
