import { ArrowDownIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { Tab } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import PureModal from 'Components/modal/PureModal';

const SectionTypeServicePopUp = ({ title, data, image = false, desc, position = "left" }) => {

  const [open, setOpen] = useState(false)
  const [dataModal, setDataModal] = useState([])


  const renderItem = (item, i) => {
    const { title, desc } = item;

    return (
     
        <div className="flex space-x-1 items-start w-full py-3 px-4 text-center justify-start bg-gray-50 rounded-md border border-gray-200">
          <span><CheckCircleIcon className='w-4 mx-auto relative top-1  text-bg-bluePresik' /></span>
          <a href="/" className="font-medium text-bg-bluePresik lg:text-base text-gra-500  text-left">
            {title}
          </a>
        </div>
    );
  };

  const openModal = (data) => {
    console.log(data);
    setOpen(true)
    setDataModal(data)
  }
  const ContentModal = () => {
    return (
      <Fragment>
        <div className='bg-bluePresik px-10 py-3'>
          <h2 className='text-white font-medium text-xl'>CARACTERISTICAS</h2>
        </div>
       <div className='grid md:grid-cols-3 gap-5 p-10'>
            {dataModal && dataModal.map((item) => (
                <div className='py-10 px-4 bg-gray-100 border border-gray-300 text-center rounded-xl shadow-sm flex flex-col justify-center items-center space-y-3 cursor-pointer' key={item.title}>
                    {item.title}
                </div>
            ))}
        </div>
      </Fragment>
 
    );
}

  return (
    <Fragment>
    
    <div className={`md:flex relative overflow-hidden ${position === "left" ? "" : "flex-row-reverse"} `}>
       <div
            className={`h-"? 96w-96 bg-gradient-to-tr from-yellow-200 to-yellow-300 absolute -bottom-52 hidden md:block z-20  right-10`}
            style={{ borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%" }}
            data-aos="fade-up"
            data-aos-duration="2500"
          />
      <div className='md:w-1/3 w-full '>
      {position === "left" ? (
        <img src={image} className='w-full h-full object-cover clickpack' alt={data?.alt}  style={{"clip-path": "circle(64% at 35% 51%)"}}/>

      ):
      <img src={image} className='w-full h-full object-cover clickpack' alt={data?.alt}  style={{"clip-path": "circle(64% at 64% 51%)"}}/>
}
      </div>
      <div className='md:w-2/3 flex flex-col py-10 px-2 md:px-10 z-10'>
        <h2 className='font-lato font-semibold lg:text-3xl text-2xl lg:w-full relative text-bg-bluePresik leading-none max-w-2xl mx-auto uppercase text-center'>{title}</h2>
        <p className='lg:text-xl text-lg font-light text-center mt-2 mb-10 max-w-6xl mx-auto font-roboto'>{data.desc}</p>
        {data.services && Object.keys(data.services).length === 1 ? (
        <div className='bg-white px-4 py-3 rounded-md border-2 border-gray-200 mx-3 md:mx-0'>

 <Tab.Group>
 
 <Tab.Panels>
   {Object.keys(data.services).map((serviceCategory, index) => (
     <Tab.Panel key={index} className="">
      <h2 className='font-lato font-semibold lg:text-xl text-xl lg:w-full relative text-bg-bluePresik leading-none mb-5 uppercase text-left'>Caracteristicas</h2>
       <div className="grid lg:grid-cols-4 justify-stretch items-stretch md:grid-cols-2 lg:gap-x-4 justify-items-center gap-y-4  gap-x-10 lg:text-lg text-base text-gray-600 max-w-6xl mx-auto  w-full">
         {data.services[serviceCategory].map((item, i) => renderItem(item, i))}
       </div>
     </Tab.Panel>
   ))}
 </Tab.Panels>
</Tab.Group>
</div>
) : (
  <div className='grid md:grid-cols-3 gap-5'>
  {data.services && Object.keys(data.services).map((serviceCategory, index) => (
    <div className={`p-10 ${position !== "left" ? "bg-gray-100" : "bg-white"} border border-gray-300 rounded-xl shadow-sm flex flex-col justify-center items-center space-y-3 cursor-pointer`} key={index} onClick={()=> openModal(data.services[serviceCategory])}>
      <p className='text-center uppercase font-lato font-semibold  text-bg-bluePresik' >{serviceCategory}</p>
      <span className="text-bg-bluePresik underline">Ver más</span>
    </div>
  ))}
</div>
)}
      </div>

    </div>
    <PureModal open={open} onClose={() => setOpen(false)} width="sm:w-5/6 md:max-w-5xl">
       <ContentModal />
      </PureModal>
    </Fragment>

  );
};

export default SectionTypeServicePopUp;
