import React from "react";
import PageStandard from "Components/Page";
import Section from "Components/Section";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";

const HabeasData = () => {

  const ContentHero = ({ title, text, styleText = "lg:text-6xl text-4xl" }) => {
    return (
      <div
        className={
          "md:pt-10 flex flex-col justify-center mb-0 pb-10 pt-10  lg:pb-10 lg:px-0 px-5  mx-auto mt-16  p-2 bg-gray-800 w-full"
        }
      >
        <h2
          className={`font-lato text-white ${styleText} uppercase font-semibold text-center`}
        >
          {title}
        </h2>
        <p className="font-roboto lg:text-2xl text-lg text-white font-light md:pb-2 text-center md:mt-8 mt-5 max-w-6xl mx-auto">
          {text}
        </p>

        <ChevronDoubleDownIcon className="w-10 mx-auto text-white animate-pulse mt-4" />
      </div>
    );
  };

  return (
    <PageStandard color="bg-gray-200">
      <ContentHero title={"Política de Habeas Data"} text={""} />

      <Section spaceTop="py-10 md:py-20">
        <div className="justify-center items-start w-full p-6 flex flex-col space-y-4 text-base">

          <section>
            <h2 className="text-2xl font-medium">1. Introducción</h2>
            <p>
              Presik se compromete a proteger la privacidad y la seguridad de
              sus datos personales. En cumplimiento de la Ley 1581 de 2012 y su
              Decreto Reglamentario, establecemos esta política para informar a
              nuestros usuarios sobre cómo recopilamos, usamos, almacenamos y
              protegemos su información personal.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-medium">2. Autorización para el Tratamiento de Datos</h2>
            <p>
              Al proporcionar sus datos personales a Presik SAS, usted autoriza
              de manera expresa y voluntaria el tratamiento de dicha
              información. Esta autorización es necesaria para llevar a cabo las
              actividades relacionadas con:
            </p>
            <ul className="list-disc list-inside">
              <li>
                Marketing: Envío de información sobre productos, servicios,
                promociones y novedades.
              </li>
              <li>
                Atención al Cliente: Responder consultas, quejas y sugerencias.
              </li>
              <li>
                Análisis: Realizar estudios estadísticos y de mercado para
                mejorar nuestros servicios.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-medium">3. Tipos de Datos Recopilados</h2>
            <p>Podemos recopilar los siguientes tipos de datos personales:</p>
            <ul className="list-disc list-inside">

              <li>Nombre completo</li>
              <li>Dirección de correo electrónico</li>
              <li>Número de teléfono</li>
              <li>Información de contacto adicional</li>
              <li>
                Cualquier otra información que usted decida proporcionarnos
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-medium">4. Derechos del Titular de los Datos</h2>
            <p>Usted tiene derecho a:</p>
            <ul className="list-disc list-inside">

              <li>Acceder a sus datos personales.</li>
              <li>Rectificar datos inexactos o incompletos.</li>
              <li>Cancelar su información cuando lo desee.</li>
              <li>
                Oponerse al tratamiento de sus datos en ciertas circunstancias.
              </li>
            </ul>
            <p>
              Para ejercer estos derechos, puede enviar una solicitud a{" "}
              <a href="mailto:director.administrativo@presik.com">
                director.administrativo@presik.com
              </a>
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-medium">5. Opción de Exclusión</h2>
            <p>
              Si desea dejar de recibir comunicaciones de marketing o cualquier
              otra información, podrá solicitarlo en cualquier momento. Para
              ello, simplemente envíe un correo a{" "}
              <a href="mailto:director.administrativo@presik.com">
                director.administrativo@presik.com
              </a>{" "}
              con el asunto "No recibir información".
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-medium">6. Seguridad de los Datos</h2>
            <p>
              Implementamos medidas de seguridad adecuadas para proteger sus
              datos personales contra accesos no autorizados, pérdidas o
              alteraciones. Sin embargo, ninguna medida de seguridad es
              completamente infalible.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-medium">7. Modificaciones a la Política</h2>
            <p>
              Nos reservamos el derecho de modificar esta política en cualquier
              momento. Cualquier cambio será notificado a través de nuestros
              canales oficiales. Se recomienda revisar periódicamente esta
              política para estar informado sobre el manejo de sus datos.
            </p>
          </section>

          <section class="contact-info">
            <h2 className="text-2xl font-medium">8. Contacto</h2>
            <p>
              Si tiene preguntas o inquietudes sobre esta política o el
              tratamiento de sus datos, no dude en ponerse en contacto con
              nosotros a través de:
            </p>
            <ul>
              <li>
                Correo electrónico:{" "}
                <a href="mailto:director.administrativo@presik.com">
                  director.administrativo@presik.com
                </a>
              </li>
              <li>Dirección: Cra. 27 #37-33</li>
              <li>Teléfono: 324 5840917</li>
            </ul>
          </section>
        </div>
      </Section>
    </PageStandard>
  );
};

export default HabeasData;
