import React from 'react'
import hoteImg from '../Assets/images/home-vigilance-section.jpg'
import "./modulesPages.css";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";


const Vigilancia = () => {

	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation();

  return (
	<section className="h-min pt-40 md:mb-48 mb-20 overflow-x-hidden w-screen relative">
	<div className=" flex flex-col-reverse md:flex-row md:justify-between justify-center items-center container mx-auto">
		<div className="relative md:w-2/3  px-5 w-full space-y-8 pt-10 mt-28  md:pt-0 md:mt-0  md:pr-32">

		<h3 className="mt-14 md:mt-8 text-gray-600 md:text-xl text-4xl uppercase font-semibold ">Modulos especializados</h3>

		<h2 className='mt-14 md:mt-8 text-gray-900 md:text-5xl text-4xl uppercase font-semibold w-11/12 tracking-wide'>EMPRESAS DE VIGILANCIA</h2>
		<p className="md:text-2xl text-lg text-gray-700 font-light pb-2  mt-8 "></p>
		<a href="#modules-section" type="button" className="text-2 md:text-xl mt-4 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 duration-100 ease-linear transform hover:text-black flex gap-4 items-center w-52">COMENZAR <ArrowLongRightIcon className="h-8 text-black hover:scale-125" /></a>
		</div>

		<div className="mx-auto flex  items-start md:w-2/5  w-screenjustify-center md:justify-start">
		<img src={hoteImg} alt="hotel " className='module-page-img'  />
        </div>
      </div>
	</section>
  )
}

export default Vigilancia;