import downloadImg from 'Assets/images/download.png';
import paperImg from 'Assets/images/020-paper.png';

export default [
  {
    name: 'downloads',
    icon: downloadImg,
    items: [
      {
        name: 'tryton_version_6',
        link: 'https://downloads.tryton.org/6.0/tryton-64bit-6.0.20.exe',
      },
      {
        name: 'libreoffice',
        link:
          'https://www.libreoffice.org/donate/dl/win-x86_64/7.1.3/en-US/LibreOffice_7.1.3_Win_x64.msi',
      },
    ],
  },
  {
    name: 'templates',
    icon: paperImg,
    items: [
      {
        name: 'parties_template',
        link: 'https://drive.google.com/file/d/1Y-F9-c9fCaKqaWdbSKe9j1q8Kz3KcZkI/view',
      },
      {
        name: 'product_template',
        link: 'https://drive.google.com/file/d/1qT1U_iw7at88zuixgSlGEvEBE8qMQ0bH/view',
      },
      {
        name: 'inventory_template',
        link: 'https://drive.google.com/file/d/1t6ZtErKAg7n_kkjKKZAHZiaDwUGFkNV-/view',
      },
      {
        name: 'opening_balances_template',
        link: 'https://drive.google.com/file/d/1utiUSW2VOtI2raJOx401YBFveBYB65dZ/view',
      },
      {
        name: 'staff_events_template',
        link: 'https://drive.google.com/file/d/1LFtJ4vrFmT1S7SQKaq6g1SbLu_aQXpBQ/view',
      },
      {
        name: 'note_account_template',
        link: 'https://drive.google.com/file/d/1AC-_TpD_PtPbZeUtuOP07Lbq83bwf-We/view',
      },
      {
        name: 'staff_template',
        link: 'https://drive.google.com/file/d/15aPhJjmb5n5VMEu1C8Fz8136PsgHgXsS/view',
      },
    ]
  },
  {
    name: 'videos',
    icon: downloadImg,
    items: [
      {
        name: 'support_document_supplier',
        kind: 'video',
        link: 'https://youtu.be/wC9T6tR39aI',
      },
      {
        name: 'radian_event_configuration',
        kind: 'video',
        link: 'https://youtu.be/7Z6OlgUZvmc',
      },
      {
        name: 'pos_basic',
        kind: 'video',
        link: 'https://youtu.be/WvRCG37lUtU',
      },
      {
        name: 'pos_restaurant',
        kind: 'video',
        link: 'https://youtu.be/8Y1nuu77gyY',
      },
      {
        name: 'Electronic_invoice',
        kind: 'video',
        link: 'https://drive.google.com/file/d/1ge7yW6Hk5kVkmC5vELlIeprcow8m9mRu/view',
      },
      {
        name: 'Acknowledgment_Receipt',
        kind: 'video',
        link: 'https://drive.google.com/file/d/10Zj5iY1vfnxgvrC7IxTy_Ky_2SD-b8xH/view',
      },
      {
        name: 'creating_products',
        kind: 'video',
        link: 'https://www.youtube.com/watch?v=7bk5XU1t-pM',
      },
      {
        name: 'creating_parties',
        kind: 'video',
        link: 'https://www.youtube.com/watch?v=2sAXCZ1_l7A',
      },
      {
        name: 'internal_shipment',
        kind: 'video',
        link: 'https://www.youtube.com/watch?v=EROsY8nxzx8&t=93s',
      },
      {
        name: 'creating_employees',
        kind: 'video',
        link: 'https://www.youtube.com/watch?v=PUHR2TE23fU',
      },
      {
        name: 'create_user_management',
        kind: 'video',
        link: 'https://www.youtube.com/watch?v=9QaJV4pV4fw',
      },
      {
        name: 'downloading_tryton_and_libreoffice',
        kind: 'video',
        link: 'https://www.youtube.com/watch?v=LB3brnyy7Wc',
      },
    ],
  },
  {
    name: 'commercial',
    icon: downloadImg,
    items: [
      {
        name: 'brochure',
        link:
          'https://drive.google.com/file/d/1rbUukZeNCNzF9d2QmaPGobm-vL9B7FIy/view',
      },
      {
        name: 'license_tryton',
        link:
          'https://drive.google.com/file/d/16WDvYwlSnp_5T77483LG_w9_fOeVMIFV/view',
      },
    ],
  },
];
