import React, { useState } from 'react';
import marko from "../Assets/images/mockup-tablet.webp";
// import marko from "../Assets/images/mockup-tablet.png";

const VideoMockup = ({ video, mode = "image", image = "", component }) => {
  // const [isLoading, setIsLoading] = useState(true);

  // const handleVideoLoad = () => {
  //   console.log("paso ṕr aca");
  //   setIsLoading(false);
  // };

  return (
    <div className="relative -bottom-16 w-full" data-aos="fade-up" data-aos-duration="1000">
      <div className="md:pt-6 md:pl-16 md:pr-20 pr-3 pl-2 pt-2">
        {mode === "image" ? (
          <img src={image} className="w-full h-1/3 relative left-1" alt="Image hero presik" />
        ) : mode === "video" ? (
          // <video  src={video} loop className="w-full h-full relative left-2 top-2 " onLoadedData={handleVideoLoad} />
          <video  src={video} loop autoPlay className="w-full h-full relative left-2 top-2 " />
        ) : (
          // <ComponenteEjemplo />
          <div>aca es un componente</div>
        )}
      </div>
      <img src={marko} className="z-30 absolute top-0" alt="Marko" />
    </div>
  );
};

export default VideoMockup;
