import React from 'react';
import {useTranslation} from 'react-i18next';

export default function Page_Error() {
  const {t} = useTranslation();
  return (
    <div className='h-screen w-screen bg-bluePresik flex flex-col space-y-8 justify-center items-center' >
      <h1 className="text-7xl text-yellow-300 font-bold text-center ">
      
        404 Error
      </h1>
        <h3 className='text-3xl text-white'>{t('pague404.content')}</h3>
        <a href="/" type="button" className="text-2 lg:text-xl mt-4 bg-yellow-300 lg:w-max rounded-full shadow-md py-2 px-8 hover:scale-110 duration-100 ease-linear transform hover:text-black flex gap-4 items-center justify-center w-52">HOME <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-8 g p-1 text-black hover:scale-125"><path fill-rule="evenodd" d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z" clip-rule="evenodd"></path></svg></a>
    </div>
  );
}
