import React from "react";
import "./Page.css";

function PageStandard(props) {
  const {overflow = false, color="bg-white" , title, description} = props


  return (
    <div className={`w-full ${color}  ${overflow ? "overflow-hidden": ""}`} >
    
      {props.image && (
        <img
          src={props.image}
          className="page-standard-img"
          alt="page-standard-img"
        />
      )}
      <div className="w-full flex flex-col justify-center ">{props.children}</div>
    </div>
  );
}

export default PageStandard;
