import React from "react";
import { useTranslation } from "react-i18next";

import PageStandard from "Components/Page";
import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import "./Integrations.css";
import PageHeroStandard from "Components/PageHeroStandard";
import Section from "Components/Section";
import CarouselIntegration from "Home/CarouselIntegration";
import team from "Assets/images/polygon.svg";


function Integrations() {
  const { t } = useTranslation();
  return (
    <PageStandard buttonContactUs={false} >
      <PageHeroStandard backgroundHero="imageRoundHeroText" image={team}>
        <PageTitle title="integrations.title" />
        <PageDescription text="integrations.content" />
      </PageHeroStandard>
      <Section spaceTop="" marginBottom="mb-32">
        <CarouselIntegration />
      </Section>
      {/* <CarouselIntegration /> */}

    </PageStandard>
  );
}

export default Integrations;
