import classNames from 'classnames'
import React from 'react'

const CardGraph = ({title, children, desc, style="bg-bluePresik"}) => {
  return (
    <div className={classNames("bg-bluePresik text-white w-full min-h-[400px] p-8 rounded-md shadow-md text-center", style)}>
        <h3 className='text-3xl font-semibold font-lato'>{title}</h3>
        {children}
        <div className='text-left w-full text-2xl'>{desc}</div>
    </div>
  )
}

export default CardGraph