import React from "react";
import "./CustomersSection.css";
import imgShopify from "Assets/images/shopify.webp";
import imgMercado from "Assets/images/mercado-libre2.webp";
import imgOpenPay from "Assets/images/open-pay.webp";
import imgSendGrid from "Assets/images/send-grid.webp";
import imgCoordinadora from "Assets/images/coordinadora.webp";
import imgDelta from "../Assets/images/delta.webp";
import imgRappi from "../Assets/images/rappi.webp";
import imgWubook from "../Assets/images/wubook.webp";
import imgAutocore from "../Assets/images/logoautocore.png";
import logoRdebank from "../Assets/images/logo-redebank.png";
import logoTwilio from "../Assets/images/logo-twilio.png";
import logoMetabase from "../Assets/images/logo-metabase.png";

const CarouselIntegration = () => {
  const styleItem = " md:w-52 flex justify-center items-center "
  const logos = [
    ["Mercado Libre", imgDelta, [180, 80], "#"],

    ["Shopify", imgShopify, [180, 80], "#"],
    // ["Linio", imgLinio, [180, 80], "#"],
    ["Mercado Libre", imgMercado, [180, 80], "#"],
    ["Open Pay", imgOpenPay, [180, 80], "#"],
    ["Send Grid", imgSendGrid, [180, 80], "#"],
    ["Coordinadora", imgCoordinadora, [180, 80], "#"],
    ["Wubook", imgWubook, [180, 80], "#"],
    // ["Auto Core", imgAutocore, [180, 80], "#"],
    ["Logo Redebank", logoRdebank, [180, 80], "#"],
    ["Logo Metabase", logoMetabase, [180, 80], "#"],
    ["Logo Twilio", logoTwilio, [180, 80], "#"],
  ];


  return (
    <>

	  <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-x-6 -gap-y-2 md:gap-12 md:gap-y-0 max-w-7xl mx-auto justify-items-center md:pt-20 ">

{logos.map((item, key) =>{
			return(
			 <div className={styleItem} key={key}>
			   <img
				 key={key}
				 src={item[1]}
				 className=" w-32 md:w-44  gray-scale"
			   />
			 </div>
		   )
		})}
	  </div>
    </>
  );
};

export default CarouselIntegration;
