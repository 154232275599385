import React, { Component } from "react";
import { BrowserRouter, Route, Switch , Redirect} from "react-router-dom";
import WhatsappBtn from "./Components/WhatsappBtn";
import Menu from "Menu/Nav.js";
import Home from "Home/Home";
import Footer from "Footer/Footer";
import PageError from "PageError/PageError";
import TrytonPage from "Solutions/Tryton";
import DashboardPage from "Solutions/Dashboard";
import ElectronicBillingPage from "Solutions/ElectronicBilling";
import ElectronicPayrollPage from "Solutions/ElectronicPayroll";
import IntegrationsPage from "Solutions/Integrations";
import TeamPage from "AboutUs/Team";
import ContactPage from "AboutUs/Contact";
import FaqPage from "AboutUs/FAQ";
import DocumentationPage from "Help/Documentation";
import ResourcesPage from "Help/Resources";
import SupportPage from "Help/Support";
import PayZonePage from "Billing/PayZone";
import HotelPage from "./ModulesPages/Hotel"
import AgroPage from "./ModulesPages/Agro";
import VigilanciaPage from "./ModulesPages/Vigilacia";
import LabPage from "./ModulesPages/Lab";
import Cookies from "Components/Cookies";
import LandingHotel from "Landings/LandingHotel";
import PageHoteles from "Solutions/PageHoteles";
import PageWorkWithUs from "WorkUs/LandingWorkWithUs";
import SinglePageWorkWithUs from "WorkUs/SingleLandingWorkWith";
import HabeasData from "WorkUs/habeasData";
import "App.css";


class App extends Component {
  render() {
    return (
      <div className="main">
        <div id="app-content">
          <Menu/>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/solutions/tryton" component={TrytonPage} />
              <Route
                path="/solutions/electronic_billing"
                component={ElectronicBillingPage}
              />
              <Route
                path="/solutions/electronic_payroll"
                component={ElectronicPayrollPage}
              />
              <Route
                path="/solutions/integrations"
                component={IntegrationsPage}
              />
              <Route path="/solutions/dashboard" component={DashboardPage} />
              <Route path="/solutions/software-hoteles" component={PageHoteles} />
              <Route path="/solutions/software-hoteleria">
                <Redirect to="/solutions/software-hoteles" />
              </Route>
              <Route path="/about_us/team" component={TeamPage} />
              <Route path="/about_us/contact" component={ContactPage} />
              <Route path="/about_us/faq" component={FaqPage} />
              <Route path="/help/documentation" component={DocumentationPage} />
              <Route path="/help/resources" component={ResourcesPage} />
              <Route path="/help/support" component={SupportPage} />
              <Route path="/billing/pay_zone" component={PayZonePage} />
              <Route path="/modulos/hotel" component={HotelPage} />
              <Route path="/modulos/agro" component={AgroPage} />
              <Route path="/modulos/vigilancia" component={VigilanciaPage} />
              <Route path="/modulos/laboratorio" component={LabPage} />
              <Route path="/landing" component={LandingHotel} />
              <Route path="/about_us/trabaje-con-nosotros" exact component={PageWorkWithUs} />
              <Route path="/about_us/trabaje-con-nosotros/:slug" component={SinglePageWorkWithUs } />
              <Route path="/habeas-data" component={HabeasData} />
              <Route component={PageError} />
            </Switch>
          </BrowserRouter>
        </div>
        <WhatsappBtn/>
        <Footer/>
        <Cookies />
      </div>
    );
  }
}

export default App;
