import React from "react";
import PageStandard from "Components/Page";
import {jobs, jobsCustomer} from "./data";
import Section from "Components/Section";
import { BriefcaseIcon, ChevronDoubleDownIcon, ClockIcon, CurrencyDollarIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";

const LandingWorkWithUs = () => {

  const JobList = ({ jobs, jobsCustomer }) => {
    return (
      <div className="max-w-7xl rounded-md  overflow-hidden divide-y divide-gray-300">
        {jobs.map((job, index) => (
          <div key={index} className="bg-white p-8 shadow-md  flex flex-col md:flex-row flex-">
            <div className="space-y-2 w-[90%]">
            <h2 className="text-2xl font-semibold">{job.title}</h2>
            <div className="md:space-x-3 space-y-3 md:space-y-0 md:flex divide-x md:divide-gray-800 font-semibold">
              <span className=" flex items-start md:items-center md:space-x-2">
                <CurrencyDollarIcon className="w-4" />
                <span>{job?.salary}</span>
              </span>
              <span className="md:pl-3 flex items-start md:items-center space-x-2">
              <BriefcaseIcon className="w-4" />
                <span>{job.typeContract}</span>
              </span>
              <span className="md:pl-3 flex items-start md:items-center space-x-2">
              <ClockIcon className="w-4" />
                <span>{job.type}</span>
              </span>
              <span className="md:pl-3 flex items-start md:items-center space-x-2">
                <MapPinIcon className="w-4" />
                <span>{job.place}</span>
              </span>
            </div>
            <p className="pb-2">{job.shortDesc}</p>
            {/* <div dangerouslySetInnerHTML={{ __html: job.longDesc }} /> */}
            <a
              className="underline text-bg-bluePresik cursor-pointer"
              href={`/about_us/trabaje-con-nosotros/${job.slug}`}
            >
             Ver oferta
            </a>
            </div>
            <div className="w-[20%]  items-center justify-center hidden md:flex">
              <img src={job.logo} className="h-[30px]" />
            </div>
          </div>
        ))}
        {jobs && jobs.length == 0 && (
          <p className="text-xl">
            No hay propuestas publicadas
          </p>
        )}
      </div>
    );
  };

  const ContentHero = ({title, text, styleText="lg:text-6xl text-3xl"}) => {
    return (
        <div className={"md:pt-10 flex flex-col justify-center mb-0 pb-10 pt-10  lg:pb-10 lg:px-0 px-5  mx-auto md:mt-16 mt-16  p-2 bg-gray-800 w-full"}>
          <h2 className={`font-lato text-white ${styleText} uppercase font-semibold text-center`}>
            {title}
          </h2>
          <p className="font-roboto lg:text-2xl text-lg text-white font-light md:pb-2 text-center md:mt-8 mt-5 max-w-6xl mx-auto">
            {text}
          </p>

          <ChevronDoubleDownIcon className="w-10 mx-auto text-white animate-pulse mt-4" />
        </div>
    );
  };

  return (
    <PageStandard color="bg-gray-200">
      <ContentHero   title={"TRABAJA CON NOSOTROS"} text="En Presik, somos una empresa de tecnología que prioriza el bienestar y la comodidad de nuestro equipo, ofreciendo un ambiente de trabajo agradable y motivador." />

      <Section spaceTop="py-10 md:pt-20">
        <div className="flex justify-center w-full space-x-10">
          <JobList jobs={jobs} />
        </div>
      </Section>
      <ContentHero   title={"TRABAJA CON NUESTROS CLIENTES"} text="También gestionamos y publicamos ofertas de empleo para nuestros clientes, conectándolos con el talento que necesitan." />
      <Section spaceTop="py-20 md:py-20">
        <div className="flex justify-center w-full space-x-10">
          <JobList jobs={jobsCustomer} />
        </div>
      </Section>
     
    </PageStandard>
  );
};

export default LandingWorkWithUs;
     {/* <div className='w-[25%] bg-bluePresik text-white p-4 space-y-3  rounded-md shadow-md  '>
        <h2 className='text-2xl font-semibold'>
          Datos de contacto
        </h2>
        <ul className='space-y-1'>
          <li className='flex space-x-3'>
            <PhoneIcon className='w-4' />
            <span>Dato xxxx</span>
          </li>
          <li className='flex space-x-3'>
            <PhoneIcon className='w-4' />
            <span>Dato xxxx</span>
          </li>
          <li className='flex space-x-3'>
            <PhoneIcon className='w-4' />
            <span>Dato xxxx</span>
          </li>
          <li className='flex space-x-3'>
            <PhoneIcon className='w-4' />
            <span>Dato xxxx</span>
          </li>
          <li className='flex space-x-3'>
            <PhoneIcon className='w-4' />
            <span>Dato xxxx</span>
          </li>
        </ul>

</div> */}