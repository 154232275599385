import React from "react";
import { useTranslation } from "react-i18next";

import "./Card.css";

function Card(props) {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-50 shadow-md rounded-md relative overflow-hidden  h-full" >
      <div className="w-full h-40 md:h-44 2xl:h-52 relative">

    <img src={props.img} alt="imagenes" className="image-crazy object-cover object-center h-full w-full z-20" />
  </div>
  <div className="px-4 py-10 space-y-3">
      <h2 className="font-semibold lg:text-2 uppercase xl text-2xl lg:w-full relative text-gray-600 leading-none text-center font-lato">
        {t(`modules.${props.name}`)}
      </h2>
      <p className="font-roboto lg:text-2xl text-1xl font-light text-center  text-gray-700">{t(`modules.${props.desc}`)}</p>
    </div>
    </div>
  );
}

export default Card;
