import { CheckIcon, ArrowRightIcon } from "@heroicons/react/24/solid";

const dataWhyTryto = [
  "Centralizamos tu base de datos",
  "Te brindamos asesoría 24/7",
  "Expandimos constantemente las funcionalidades",
  "Ahorra papelería y digitaliza todo tu proceso",
  "Integra tus datos a software de terceros ",
  "Te brindamos multiplataformas para tu comodidad"
];

const WhyPresik = ({image = false}) => {
  return (
    <div className='grid md:grid-cols-2 '>
      <div className='space-y-8 md:pr-20 order-last md:order-none px-5 md:px-0'>
        <h2 className="mt-8 text-white md:text-4xl text-2xl uppercase font-semibold  text-left font-lato"><span className="text-yellow-300">¿Por qué </span>NOSOTROS?</h2>
        <div className='text-gray-300 text-base grid md:grid-cols-2 grid-cols-1 gap-5 pb-2'>
          {dataWhyTryto.map((item, key) => {
            return <div key={key} className="flex p-4 items-center space-x-4 rounded-md bg-gray-700 shadow-md relative">
                
                <div className=" bg-yellow-300 rounded-md transform rotate-45  p-1"><CheckIcon className="w-4 text-black z-10 relative transform -rotate-45" /></div>
                <span>{item}</span></div>;
          })}
        </div>
        <buttom className="text-yellow-300 cursor-pointer mt-4 flex space-x-4 hover:space-x-10 transform duration-150 group"><span>Agenda un reunion con nosotros</span> <ArrowRightIcon className="w-5 group-hover:pl-5" /></buttom>
      </div>
      <div>
        <img src={image} className='md:w-1/2 w-screen md:absolute object-cover top-0 h-52 md:h-full object-' ></img>
      </div>
    </div>
  );
};

export default WhyPresik;
