import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Aos from "aos";
import "aos/dist/aos.css";

import PageStandard from "Components/Page";
import PageTitle from "Components/PageTitle";
import PageDescription from "Components/PageDescription";
import "./ElectronicBilling.css";
import ElectronicBillingImg from "Assets/images/electronic_billing.png";
import PageHeroStandard from "Components/PageHeroStandard";
import team from "Assets/images/future2.png";
import Section from "Components/Section";

function ElectronicBilling() {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <PageStandard buttonContactUs={false}>
      <PageHeroStandard backgroundHero="imageRoundHeroText" image={team}>
        <PageTitle title="electronic_billing.title" color="dark" />
        <PageDescription text="electronic_billing.content" color="dark" />
      </PageHeroStandard>
      <Section>
        <div class=" flex flex-col md:flex-row mx-auto px-4 md:px-20  space-x-20 ">
          <div data-aos="fade-right" class="md:w-1/2 md:pr-10 bg-gray-50 rounded-md p-10 shadow-md">
            <h2 class="font-lato font-semibold lg:text-3xl text-3xl lg:w-full relative text-gray-600 leading-none">
              {t("electronic_billing.why_electronic_billing")}
            </h2>
            <p class="lg:text-1xl text-lg text-gray-700 font-light pb-2  mt-8 ">
              {t("electronic_billing.cause_use_electronic_billing")}
            </p>
          </div>
          <div data-aos="fade-right" class="md:w-1/2 md:pr-10 bg-gray-50 rounded-md p-10 shadow-md">
            <h2 class="font-semibold font-lato lg:text-3xl text-3xl lg:w-full relative text-gray-600 leading-none">
              {t("electronic_billing.why_prefer_us")}
            </h2>
            <p class="lg:text-1xl text-lg text-gray-700 font-light pb-2  mt-8 ">
              {t("electronic_billing.cause_prefer_us")}
            </p>
          </div>
        </div>
      </Section>
    </PageStandard>
  );
}

export default ElectronicBilling;
