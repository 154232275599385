import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Loading from "Components/Loading";
import 'fontsource-lato'; // Importa la fuente "Lato"
import "./i18n";
import "./output.css"
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";



ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <App /> 
  </Suspense>,
  document.getElementById("root")
);
