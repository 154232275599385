import React from "react";
import { useTranslation } from "react-i18next";

import "./Page.css";

function PageDescription(props) {
  const { t } = useTranslation();
  const {textAling = "text-center", color="text-white"} = props


  let colorStyle = "white";
  if (props.color) {
    colorStyle = props.color;
  }
  return (
    <div className="mx-auto  max-w-3xl mt-2  flex justify-center">
      <p className={`font-roboto lg:text-2xl text-xl font-normal ${textAling} ${color}`}  >
        {t(props.text)}
      </p>
    </div>
  );
}

export default PageDescription;
